import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { store, persistor } from './redux/config/Store';
import { PersistGate } from 'redux-persist/integration/react';
import Home from './pages/home'
import About from './pages/about'
import Membership from './pages/membership'
import HealthInsurance from './pages/health-insurance'
import Contact from './pages/contact'
import Nominations from './pages/nominations'
import Votes from './pages/votes'
import Movie from './pages/movie'
/** Panel **/
import Dashboard from './panel/dashboard'
import Users from './panel/users'
import Members from './panel/members'
import Positions from './panel/positions'
import NomineeRow from './panel/nominee-row'
import Movies from './panel/movies'
import Nominees from './panel/nominees'
import VoteResults from './panel/vote-results'
import Petitions from './panel/petitions'


const App = ({ }) => {

  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <Route path="/" exact component={Home} />
            <Route path="/home" exact component={Home} />
            <Route path="/nominations" exact component={Nominations} />
            <Route path="/movie/:mid" exact component={Movie} />
            <Route path="/votes" exact component={Votes} />
            <Route path="/about" exact component={About} />
            <Route path="/membership" exact component={Membership} />
            <Route path="/health-insurance" exact component={HealthInsurance} />
            <Route path="/contact" exact component={Contact} />
            {/** Panel **/}
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/users" exact component={Users} />
            <Route path="/members" exact component={Members} />
            <Route path="/positions" exact component={Positions} />
            <Route path="/nominee-row" exact component={NomineeRow} />
            <Route path="/movies" exact component={Movies} />
            <Route path="/nominees" exact component={Nominees} />
            <Route path="/vote-results" exact component={VoteResults} />
            <Route path="/petitions" exact component={Petitions} />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </>
  );

}

export default App;