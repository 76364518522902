import React, { useMemo, useState } from 'react';
import Page from './page';
import moment from 'moment';
import { connect } from 'react-redux';
import NomineesActions from '../../redux/actions/Nominees';
import MoviesActions from '../../redux/actions/Movies';
import NomineeRowActions from '../../redux/actions/NomineeRow';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Nominees = ({ nominees, movies, nomineeRow, getNomineeRows, getMovies, getNominees, getNominee, setNominee, setUpdate, setRemove }) => {

    const [show, setShow] = useState({ add: false, edit: false }),
        [nid, setNid] = useState(null),
        [listMovies, setListMovies] = useState([]),
        [listNomineeRow, setListNomineeRow] = useState([]),
        [form, setForm] = useState({ active: 1 }),
        [errors, setErrors] = useState({})

    useMemo(() => {
        getNominees()
        getMovies()
        getNomineeRows()
    }, []);

    useMemo(() => {
        if (nominees.selected) {
            setForm({
                nominee_row_id: listNomineeRow.filter((row)=> row.value === nominees.selected.nominee_row_id)[0],
                movie_id: listMovies.filter((row)=> row.value === nominees.selected.movie_id)[0],
                actor: nominees.selected.actor,
                active: parseInt(nominees.selected.active)
            })
            setShow({ ...show, edit: true })
        }
    }, [nominees.selected]);

    useMemo(() => {
        if (movies.list) {
            let list = []
            movies.list.map(row => {
                list.push({ value: row.movie_id, label: row.name })
            })
            setListMovies(list)
        }
    }, [movies.list]);

    useMemo(() => {
        if (nomineeRow.list) {
            let list = []
            nomineeRow.list.map(row => {
                list.push({ value: row.nominee_row_id, label: row.name })
            })
            setListNomineeRow(list)
        }
    }, [nomineeRow.list]);

    const

        /**
         * Handled add new position
         */
        handledAdd = () => {
            if (handleValidate()) {
                setNominee({
                    nominee_row_id: form.nominee_row_id.value,
                    movie_id: form.movie_id.value,
                    actor: form.actor
                })
                setShow({ ...show, add: false })
                setForm({ name: "", active: 1 })
            }
        },

        /**
         * Handled update nominee row by nid
         */
        handledUpdate = () => {
            if (handleValidate()) {
                setUpdate(nid, {
                    nominee_row_id: form.nominee_row_id.value,
                    movie_id: form.movie_id.value,
                    actor: form.actor,
                    active: form.active
                })
                setShow({ ...show, edit: false })
                setForm({ name: "", active: 1 })
            }
        },

        /**
         * Handled show edit
         */
        handledShowEdit = (nid) => {
            setNid(nid)
            getNominee(nid)
        },

        /**
         * Handled remove nominee row
         */
        handledRemove = (nid) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Eliminar Renglón</h3>
                            <p>¿Realmente deseas eliminar este renglón?</p>
                            <div className="text-center">
                                <button className="btn btn-secondary" onClick={onClose} style={{ float: "left" }}><i className="fas fa-times"></i>&nbsp;Cancel</button>
                                <button className="btn btn-success" onClick={() => setRemove(nid, onClose)} style={{ float: "right" }}><i className="fas fa-check"></i>&nbsp;Aceptar</button>
                            </div>
                        </div>
                    )
                }
            });
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = () => {
            setErrors({})
            if (!form.nominee_row_id) {
                setErrors({ nominee_row_id: true });
                return false;
            } else if (!form.movie_id) {
                setErrors({ movie_id: true });
                return false;
            } else if (!form.actor) {
                setErrors({ actor: true });
                return false;
            }
            return true;
        };

    const columns = [
        {
            name: 'ID', selector: row => row.nominated_id, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span>{row.nominated_id}</span>
            )
        }, {
            name: 'Renglón', selector: row => row.nominee_row, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{row.nominee_row}</span>
            )
        }, {
            name: 'Película', selector: row => row.movie, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{row.movie}</span>
            )
        }, {
            name: 'Actor', selector: row => row.actor, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{row.actor}</span>
            )
        }, {
            name: 'Estado', selector: row => row.active, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span style={{ color: ((parseInt(row.active)) ? "#31821b" : "rgb(200, 55, 55)") }}>{(parseInt(row.active)) ? "Activo" : "Desactivo"}</span>
            )
        }, {
            name: 'Creado', selector: row => row.created, minWidth: '12%', maxWidth: '12%', sortable: true, cell: row => (
                <span>{moment(row.created).format("DD/MM/YYYY hh:mm A")}</span>
            )
        }, {
            name: '', minWidth: '8%', maxWidth: '8%', cell: row => (
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "space-between" }}>

                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Editar Usuario"
                        style={{ margin: "0 auto" }} onClick={(uid) => handledShowEdit(row.nominated_id)}><i className="far fa-edit pointer"></i></button>

                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Eliminar Usuario"
                        style={{ margin: "0 auto" }} onClick={() => handledRemove(row.nominated_id)}><i className="far fa-trash-alt pointer"></i></button>
                </div>
            )
        }
    ], paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    return (
        <Page columns={columns} paginationOptions={paginationOptions} nominees={nominees} show={show} 
              setShow={setShow} form={form} errors={errors} setForm={setForm} handledAdd={handledAdd} 
              handledRemove={handledRemove} handledUpdate={handledUpdate} listNomineeRow={listNomineeRow} listMovies={listMovies} />
    );
}

const mapStateToProps = ({ nominees, movies, nomineeRow }) => ({
    movies,
    nominees,
    nomineeRow
});

const mapDispatchToProps = () => ({
    ...MoviesActions,
    ...NomineeRowActions,
    ...NomineesActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Nominees));