import React, { Component } from 'react';
import Page from './page';


class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nav: props.nav
        };
    }

    render() {
        return (
            <Page state={this.state} />
        );
    }
}
export default Contact;