import React, { useMemo } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import MoviesActions from '../../redux/actions/Movies';
import { withRouter } from 'react-router-dom';

const Nominations = ({ movies, getMovies }) => {

    useMemo(()=>{
        getMovies()
    },[])

    return (
        <Page movies={movies} />
    );
}

const mapStateToProps = ({ movies }) => ({
    movies
});

const mapDispatchToProps = () => ({
    ...MoviesActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Nominations));