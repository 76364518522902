import React, { useMemo, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import UserActions from '../../../redux/actions/Users';
import { withRouter } from 'react-router-dom';

const Header = ({ users, setAuthentication, setSignOff, history }) => {

    const [form, setForm] = useState({}),
          [show, setShow] = useState({ login: false }),
          [errors, setErrors] = useState({});

    useMemo(() => {
        if(users.authUser) {
            setShow({ login: false })
            setForm({ username: "", password: "" })
        }
    }, [users.authUser]);

    const

        /**
         * Handled add new user
         */
        handledLogin = () => {
            if (handleValidate()) {
                setAuthentication(form, history)
             }
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = () => {
            setErrors({})
            if (!form.username) {
                setErrors({ username: true });
                return false;
            } else if (!form.password) {
                setErrors({ password: true });
                return false;
            }
            return true;
        },

        /**
         * Handled dashBoard
         */
        handledDashBoard = () => {
            history.push("/users");
        },

        handledSignOff = () => {
            setSignOff(history)
        }

    return (<Page handledLogin={handledLogin} form={form} setForm={setForm} users={users} handledDashBoard={handledDashBoard}
                  handledSignOff={handledSignOff} errors={errors} show={show} setShow={setShow} />);
}

const mapStateToProps = ({ users }) => ({
    users
});

const mapDispatchToProps = () => ({
    ...UserActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Header));