import React, { useMemo, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NomineesActions from '../../redux/actions/Nominees';
import GeneralActions from '../../redux/actions/General';

const Votes = ({ users, nominees, setVote, getVoteByUser, general, getConfig, getNominees }) => {

    const [rows, setRows] = useState([]),
        [votes, setVotes] = useState([]),
        [votesOptions, setVotesOptions] = useState([]),
        [config, setConfig] = useState({ step: 0, votes: 0 })

    useMemo(() => {
        getNominees()
        getConfig()
    }, []);

    useMemo(() => {
        let list = [], keys = []
        nominees.list.map((row, key) => {
            if (!keys.includes(row.nominee_row)) {
                let options = []
                nominees.list.map((val) => {
                    if (val.nominee_row === row.nominee_row) {
                        options.push({
                            id: val.nominated_id,
                            movie: val.movie,
                            actor: val.actor
                        })
                    }
                })
                keys.push(row.nominee_row)
                list.push({
                    description: row.nominee_row,
                    options: options
                })
            }
        })
        setRows(list)
    }, [nominees.list])

    useMemo(() => {
        if (general.config) {
            setConfig(general.config)
            if (users.authUser) {
                getVoteByUser(users.authUser.user_id, general.config.step)
            }
        }
    }, [general.config])

    useMemo(() => {
        setVotesOptions(general.votes)
    }, [general.votes])

    /**
     * Handled vote by user
     * @param {*} nid 
     */
    const handledVote = (nid, description) => {
        setVote({
            nominated_id: nid,
            member_id: users.authUser.user_id,
            step: config.step
        })
        setVotesOptions(row => [...row, nid]);
        setVotes(row => [...row, description]);
    }

    return (
        <Page rows={rows} handledVote={handledVote} config={config} votes={votes} users={users} general={general} votesOptions={votesOptions} />
    );
}

const mapStateToProps = ({ users, nominees, general }) => ({
    users, nominees, general
});

const mapDispatchToProps = () => ({
    ...NomineesActions,
    ...GeneralActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Votes));