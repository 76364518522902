import React, { useMemo, useState } from 'react';
import Page from './page';
import moment from 'moment';
import { connect } from 'react-redux';
import MoviesActions from '../../redux/actions/Movies';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Movies = ({ movies, getMovies, getMovie, setMovie, setUpdate, setRemove, setUpdateCover }) => {

    const [show, setShow] = useState({ add: false, edit: false, cover: false }),
        [mid, setMid] = useState(null),
        [file, setFile] = useState([]),
        [form, setForm] = useState({ active: 1, release_date: new Date() }),
        [errors, setErrors] = useState({}),
        genders = [
            { value: "Acción", label: "Acción" },
            { value: "Aventuras", label: "Aventuras" },
            { value: "Ciencia Ficción", label: "Ciencia Ficción" },
            { value: "Comedia", label: "Comedia" },
            { value: "Drama", label: "Drama" },
            { value: "Fantasía", label: "Fantasía" },
            { value: "Musical", label: "Musical" },
            { value: "Suspense", label: "Suspense" },
            { value: "Terror", label: "Terror" },
            { value: "Melodrama ", label: "Melodrama " },
            { value: "Romance", label: "Romance" },
            { value: "Documental", label: "Documental" }
        ]

    useMemo(() => {
        getMovies()
    }, []);

    useMemo(() => {
        if (movies.selected) {
            let gender = []
            movies.selected.gender.split(",").map(row => {
                gender.push({ value: row, label: row })
            })
            setForm({
                name: movies.selected.name,
                trailer: movies.selected.trailer,
                director: movies.selected.director,
                producer: movies.selected.producer,
                script: movies.selected.script,
                gender: gender,
                cast: movies.selected.cast,
                release_date: new Date(movies.selected.release_date),
                active: parseInt(movies.selected.active)
            })
            setShow({ ...show, edit: true })
        }
    }, [movies.selected]);

    const

        /**
         * Handled add new movie
         */
        handledAdd = () => {
            if (handleValidate()) {
                let gender = []
                form.gender.map((row, key) => {
                    gender += (key+1 === form.gender.length) ? row.label : row.label+", ";
                })
                setMovie({ ...form, gender: gender })
                setShow({ ...show, add: false })
                setForm({ active: 1, release_date: new Date() })
            }
        },

        /**
         * Handled update movie by mid
         */
        handledUpdate = () => {
            if (handleValidate()) {
                let gender = []
                form.gender.map((row, key) => {
                    gender += (key+1 === form.gender.length) ? row.label : row.label+", ";
                })
                setUpdate(mid, {...form, gender: gender})
                setShow({ ...show, edit: false })
                setForm({ active: 1, release_date: new Date() })
            }
        },

        /**
         * Handled update cover
         */
        handledUpdateCover = (file) => {
            setFile(file)
            console.log(file.base64)
            setUpdateCover(mid, file.base64)
        },

        /**
         * Handled show edit
         */
        handledShowEdit = (mid) => {
            setMid(mid)
            getMovie(mid)
        },

        /**
         * Handled show picture
         */
        handledShowCover = (row) => {
            setMid(row.movie_id)
            setFile({ base64: row.cover })
            setShow({ ...show, cover: true })
        },

        /**
         * Handled remove movie
         */
        handledRemove = (mid) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Eliminar Posición</h3>
                            <p>¿Realmente deseas eliminar este posición?</p>
                            <div className="text-center">
                                <button className="btn btn-secondary" onClick={onClose} style={{ float: "left" }}><i className="fas fa-times"></i>&nbsp;Cancel</button>
                                <button className="btn btn-success" onClick={() => setRemove(mid, onClose)} style={{ float: "right" }}><i className="fas fa-check"></i>&nbsp;Aceptar</button>
                            </div>
                        </div>
                    )
                }
            });
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = () => {
            setErrors({})
            if (!form.name) {
                setErrors({ name: true });
                return false;
            } else if (!form.director) {
                setErrors({ director: true });
                return false;
            }
            return true;
        };

    const columns = [
        {
            name: 'ID', selector: row => row.movie_id, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span>{row.movie_id}</span>
            )
        }, {
            name: 'Nombre', selector: row => row.name, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{row.name}</span>
            )
        }, {
            name: 'Género', selector: row => row.gender, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{row.gender}</span>
            )
        }, {
            name: 'Fecha Estreno', selector: row => row.release_date, minWidth: '18%', maxWidth: '18%', sortable: true, cell: row => (
                <span>{moment(row.release_date).format("DD/MM/YYYY")}</span>
            )
        }, {
            name: 'Estado', selector: row => row.active, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span style={{ color: ((parseInt(row.active)) ? "#31821b" : "rgb(200, 55, 55)") }}>{(parseInt(row.active)) ? "Activo" : "Desactivo"}</span>
            )
        }, {
            name: 'Creado', selector: row => row.created, minWidth: '12%', maxWidth: '12%', sortable: true, cell: row => (
                <span>{moment(row.created).format("DD/MM/YYYY hh:mm A")}</span>
            )
        }, {
            name: '', minWidth: '10%', maxWidth: '10%', cell: row => (
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "space-between" }}>

                    <button className="btn btn-secondary btn-sm pointer" type="button"
                        style={{ margin: "0 auto" }} onClick={() => handledShowCover(row)}><i className="far fa-image pointer"></i></button>

                    <button className="btn btn-secondary btn-sm pointer" type="button"
                        style={{ margin: "0 auto" }} onClick={() => handledShowEdit(row.movie_id)}><i className="far fa-edit pointer"></i></button>

                    <button className="btn btn-secondary btn-sm pointer" type="button"
                        style={{ margin: "0 auto" }} onClick={() => handledRemove(row.movie_id)}><i className="far fa-trash-alt pointer"></i></button>
                </div>
            )
        }
    ], paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    return (
        <Page columns={columns} paginationOptions={paginationOptions} movies={movies} show={show} setShow={setShow} form={form} 
              errors={errors} file={file} setForm={setForm} handledAdd={handledAdd} handledRemove={handledRemove} 
              handledUpdate={handledUpdate} handledUpdateCover={handledUpdateCover} genders={genders} />
    );
}

const mapStateToProps = ({ movies }) => ({
    movies
});

const mapDispatchToProps = () => ({
    ...MoviesActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Movies));