import { positions as positionsTypes } from '../constants/ActionTypes';
import { general as generalTypes } from '../constants/ActionTypes';
import axios from 'axios';

/**
 * Get list of positions
 * @returns 
 */
const getPositions = () => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    getListPositions(dispatch)
};

/**
 * Add new position
 * @param {*} form 
 * @returns 
 */
const setPosition = (form) => async (dispatch) => {
    await axios.post(process.env.REACT_APP_URL + 'position', form).then(resp => {
        getListPositions(dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Get position by pid
 * @param {*} pid 
 */
const getPosition = (pid) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'position/' + pid).then(resp => {
        dispatch({ type: positionsTypes.GET_POSITION, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

/**
 * Update position by pid
 * @param {*} form 
 * @returns 
 */
 const setUpdate = (pid, form) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'position/' + pid, { ...form, active: (form.active) ? 1 : 0 }).then(resp => {
        getListPositions(dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Remove position by pid
 * @param {*} pid 
 * @param {*} onClose 
 * @returns 
 */
 const setRemove = (pid, onClose) => async (dispatch) => {
    await axios.delete(process.env.REACT_APP_URL + 'position/' + pid).then(resp => {
        getListPositions(dispatch, () => onClose())
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Export const
 */
const exportConst = {
    getPositions,
    getPosition,
    setPosition,
    setRemove,
    setUpdate
};

/**
 * Get list of positions
 * @param {*} dispatch 
 * @param {*} callBack 
 */
async function getListPositions(dispatch, callBack = function () { }) {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'positions').then(resp => {
        dispatch({ type: positionsTypes.GET_POSITIONS, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
        callBack()
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

export default exportConst;