import React from "react";
import "./style.css";
import Header from "../../modules/page/header";
import Footer from "../../modules/page/footer";
import { Col, Container, Button, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Page(props) {

  const { rows, votes, config, handledVote, users, votesOptions } = props
  return (
    <>
      <Header></Header>
      <section id="vt-top">
        <Container>
          <ul className="nav-bar">
            <li><Link to="/">Home</Link> <span>&gt;</span> <Link to="#">Votación Silla {new Date().getFullYear()}</Link> <span>&gt;</span> <Link to="#">Votaciones</Link></li>
          </ul>
          <div>
            <h2 className="title-page">Bienvenidos a las votaciones para los Premios La Silla 2019</h2>
            <p className="decription">Nominaciones oficiales de la industria cinematográfica desde enero 2020 a marzo 2022.</p>
            <p className="decription">La 2da. ronda de votaciones para los premios La Silla 2018 ya están disponibles y esperando por TU VOTO hasta el día 30 de julio hasta las 10 de la noche.</p>
          </div>
          <Image src={"../assets/la-silla.webp"} style={{ width: "100%" }} />
        </Container>
      </section>

      <section id="vt-nominations">
        <Container>
          {(parseInt(config.votes) && users.authUser) ?
            <Row>
              {
                rows.map((row, key) => {
                  let close = false
                  row.options.map((val) => {
                    if(votesOptions.includes(val.id)) {
                      close = true
                    }
                  })
                  return (<Col xs={12} md={6} lg={4} key={key}>
                    <div className="card-nomination">
                      <h2 className="title-page">{row.description}</h2>
                      <ul>
                        {
                          row.options.map((val, idx) => {
                            return (
                              <li key={idx}>
                                <p>
                                  <strong>{val.movie}</strong>
                                  <label>{val.actor}</label>
                                </p>
                                <Button disabled style={{ display: (votesOptions.includes(val.id)) ? "inline" : "none" }}><i class="fas fa-check" ></i></Button>
                                <Button onClick={() => handledVote(val.id, row.description)} style={{ display: (votes.includes(row.description) || close) ? "none" : "inline" }}>Votar</Button>
                              </li>)
                          })
                        }
                      </ul>
                    </div>
                  </Col>)
                })
              }
            </Row> :
            <div className="closed-voting">
              <p>{(!users.authUser) ? "Debe ser mienbro para poder realizar votaciones" : "Las votaciones están cerradas por el momento"}</p>
            </div>
          }
        </Container>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Page;