import React, { useState } from 'react';
import "./style.css";
import { Container, Row, Col, Image, Modal, Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

function Page(props) {

    const { handledLogin, show, setShow, form, setForm, errors, users, handledDashBoard, handledSignOff } = props

    return (
        <>
            <header id='header-page'>
                <Container>
                    <Row>
                        <Col xs={12} md={4}>
                            <Link to="/"><Image src={"../assets/logo.png"} /></Link>
                        </Col>
                        <Col xs={12} md={4}>
                            <ul id="social-media-header">
                                <li><i className="fab fa-facebook-f"></i></li>
                                <li><i className="fab fa-instagram"></i></li>
                                <li><i className="fab fa-twitter"></i></li>
                            </ul>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className="options">
                                {(users.authUser) ?
                                    <>
                                        <span style={{ display: (users.authUser.user_type !== "admin") ? "inline-block" : "none" }}>Bienvenido</span>
                                        <span style={{ display: (users.authUser.user_type === "admin") ? "inline-block" : "none" }} className='option-auth' onClick={handledDashBoard}>Panel</span>
                                        <span className='bar-auth'>|</span>
                                        <span className='option-auth' onClick={handledSignOff}>Cerrar Sesión</span>
                                    </>
                                    :
                                    <span className='button' onClick={() => setShow({ ...show, login: true })}>Iniciar Sesión</span>
                                }
                                <span className='nav'>
                                    <i className="fas fa-bars"></i>
                                    <div className='menu'>
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="#">Votación Silla 2022</Link></li>
                                            <li><Link to="/nominations">Nominaciones</Link></li>
                                            <li><Link to="/votes">Votaciones</Link></li>
                                            <li><Link to="/about">Adocine</Link></li>
                                            <li><Link to="/membership">Membresía</Link></li>
                                            <li><Link to="/health-insurance">Seguro de Salud</Link></li>
                                            <li><Link to="/contact">Contacto</Link></li>
                                        </ul>
                                    </div>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>

            {/* Login */}
            <Modal show={show.login} onHide={() => setShow({ ...show, login: false })} id="login">
                <Modal.Header closeButton>
                    <Modal.Title>Iniciar Sesión</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Correo electrónico</Form.Label>
                            <Form.Control type="email" placeholder="Escriba su correo electrónico..." style={{ borderColor: (errors.username) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                                onChange={(e) => setForm({ ...form, username: e.target.value })} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control type="password" placeholder="Escriba su contraseña..." style={{ borderColor: (errors.password) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                                onChange={(e) => setForm({ ...form, password: e.target.value })} />
                        </Form.Group>
                        <Button variant="primary" onClick={handledLogin}>Iniciar Sesión</Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    );
}

export default Page;