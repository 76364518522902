import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import "./style.css";

function Page(props) {
    
    return (
        <footer id="footer-page">
            <Container>
                <Row>
                    <Col>
                        <Image src={"../assets/logo_white_small.png"} />
                    </Col>
                    <Col>
                        <p className="copyright">© Copyright Todos los derechos reservados, ADOCINE {new Date().getFullYear()}.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Page;