import React, { useMemo, useState } from 'react';
import Page from './page';
import moment from 'moment';
import { connect } from 'react-redux';
import UserActions from '../../redux/actions/Users';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Users = ({ users, getUsers, getUser, setUser, setRemoveUser, setUpdateUser }) => {

    const [show, setShow] = useState({ add: false, edit: false }),
        [enable, setEnable] = useState(true),
        [uid, setUid] = useState(null),
        [form, setForm] = useState({ active: 1 }),
        [errors, setErrors] = useState({})

    useMemo(() => {
        getUsers("admin")
    }, []);

    useMemo(() => {
        if (users.selectedUser) {
            setForm({
                full_name: users.selectedUser.full_name,
                username: users.selectedUser.username,
                email: users.selectedUser.email,
                active: parseInt(users.selectedUser.active)
            })
            setShow({ ...show, edit: true })
        }
    }, [users.selectedUser]);

    const

        /**
         * Handled add new user
         */
        handledAdd = () => {
            if (handleValidate()) {
                setUser({
                    full_name: form.full_name,
                    username: form.username,
                    email: form.email,
                    password: form.password,
                    user_type: "admin"
                }, "admin")
                setShow({ ...show, add: false })
                setForm({ full_name: "", username: "", email: "", password: "", active: 1 })
            }
        },

        /**
         * Handled update user by uid
         */
        handledUpdate = () => {
            if (handleValidate()) {
                setUpdateUser(uid, form, "admin")
                setShow({ ...show, edit: false })
                setForm({ full_name: "", username: "", email: "", password: "", active: 1 })
            }
        },

        /**
         * Handled show edit
         */
        handledShowEdit = (uid) => {
            setUid(uid)
            getUser(uid)
        },

        /**
         * Handled remove user
         */
        handledRemove = (uid) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Eliminar Usuario</h3>
                            <p>¿Realmente deseas eliminar este usuario?</p>
                            <div className="text-center">
                                <button className="btn btn-secondary" onClick={onClose} style={{ float: "left" }}><i className="fas fa-times"></i>&nbsp;Cancel</button>
                                <button className="btn btn-success" onClick={() => setRemoveUser(uid, "admin", onClose)} style={{ float: "right" }}><i className="fas fa-check"></i>&nbsp;Aceptar</button>
                            </div>
                        </div>
                    )
                }
            });
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = () => {
            setErrors({})
            if (!form.full_name) {
                setErrors({ full_name: true });
                return false;
            } else if (!form.email || !form.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                setErrors({ email: true });
                return false;
            }

            if (!show.edit) {
                if (!form.username) {
                    setErrors({ username: true });
                    return false;
                } else if (!form.password) {
                    setErrors({ password: true });
                    return false;
                } else if (form.password !== form.reptpassword) {
                    setErrors({ reptpassword: true });
                    return false;
                }
            }
            return true;
        };

    const columns = [
        {
            name: 'ID', selector: row => row.user_id, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span>{row.user_id}</span>
            )
        }, {
            name: 'Nombre', selector: row => row.full_name, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{row.full_name}</span>
            )
        }, {
            name: 'Usuario', selector: row => row.username, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{row.username}</span>
            )
        }, {
            name: 'Email', selector: row => row.email, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{row.email}</span>
            )
        }, {
            name: 'Estado', selector: row => row.active, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span style={{ color: ((parseInt(row.active)) ? "#31821b" : "rgb(200, 55, 55)") }}>{(parseInt(row.active)) ? "Activo" : "Desactivo"}</span>
            )
        }, {
            name: 'Creado', selector: row => row.created, minWidth: '12%', maxWidth: '12%', sortable: true, cell: row => (
                <span>{moment(row.created).format("DD/MM/YYYY hh:mm A")}</span>
            )
        }, {
            name: '', minWidth: '8%', maxWidth: '8%', cell: row => (
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "space-between" }}>
                        <button className="btn btn-secondary btn-sm pointer" type="button"
                            style={{ margin: "0 auto" }} onClick={(uid) => handledShowEdit(row.user_id)}><i className="far fa-edit pointer"></i></button>
                    {(parseInt(row.editable) === 0) ?
                        <>
                            <button className="btn btn-secondary btn-sm pointer" disabled
                                style={{ margin: "0 auto" }}><i className="far fa-trash-alt pointer"></i></button>
                        </>
                        : 
                        <>
                        <button className="btn btn-secondary btn-sm pointer" type="button"
                            style={{ margin: "0 auto" }} onClick={() => handledRemove(row.user_id)}><i className="far fa-trash-alt pointer"></i></button>
                        </>
                    }
                </div>
            )
        }
    ], paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    return (
        <Page columns={columns} paginationOptions={paginationOptions} users={users} show={show} setShow={setShow} form={form} errors={errors}
            setForm={setForm} handledAdd={handledAdd} handledRemove={handledRemove} enable={enable} setEnable={setEnable} handledUpdate={handledUpdate} />
    );
}

const mapStateToProps = ({ users }) => ({
    users
});

const mapDispatchToProps = () => ({
    ...UserActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Users));