import React, { useMemo, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import GeneralActions from '../../redux/actions/General';
import { withRouter } from 'react-router-dom';

const VoteResults = ({ general, getVotesResults, getConfig, setUpdateconfig }) => {

    const [show, setShow] = useState(false),
          [form, setForm] = useState({ step: 1, votes: 1 });

    useMemo(() => {
        getVotesResults()
        getConfig()
    }, []);

    useMemo(() => {
        if(general.config) {
            setForm({
                step: general.config.step,
                votes: parseInt(general.config.votes)
            })
        }
    }, [general.config]);

    const

        /**
         * Handled turn voting on or off
         */
        handledVotes = () => {
            const frm = {...form, votes: parseInt(form.votes) === 1 ? 0 : 1 }
            setForm(frm);
            setUpdateconfig(frm)
        },

        /**
         * Handled remove position
         */
        handledStep = (step) => {
            const frm = {...form, step: step }
            setForm(frm);
            setUpdateconfig(frm)
        }

    const

        headers = [
            { label: "Renglón", key: "name" },
            { label: "Película", key: "movie" },
            { label: "Actor", key: "actor" },
            { label: "Total", key: "total" },
            { label: "Step", key: "step" }
        ],

        columns = [
            {
                name: 'Renglón', selector: row => row.name, minWidth: '25%', maxWidth: '25%', sortable: true, cell: row => (
                    <span>{row.name}</span>
                )
            }, {
                name: 'Película', selector: row => row.movie, minWidth: '25%', maxWidth: '25%', sortable: true, cell: row => (
                    <span>{row.movie}</span>
                )
            }, {
                name: 'Actor', selector: row => row.actor, minWidth: '30%', maxWidth: '30%', sortable: true, cell: row => (
                    <span>{row.actor}</span>
                )
            }, {
                name: 'Total', selector: row => row.total, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                    <span>{row.total}</span>
                )
            }, {
                name: 'Step', selector: row => row.step, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                    <span>{row.step}</span>
                )
            }

        ], paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    return (
        <Page columns={columns} paginationOptions={paginationOptions} general={general} handledVotes={handledVotes}
              show={show} setShow={setShow} headers={headers} form={form} setForm={setForm} handledStep={handledStep} />
    );
}

const mapStateToProps = ({ general }) => ({
    general
});

const mapDispatchToProps = () => ({
    ...GeneralActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(VoteResults));