import React, { useState } from "react";
import "./style.css";
import Select from 'react-select'
import Header from '../../modules/panel/header'
import Footer from '../../modules/panel/footer'
import Sidebar from '../../modules/panel/sidebar'
import DataTable from 'react-data-table-component';
import { Button, Col, Form, InputGroup, Modal, Row, Image } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FileBase64 from 'react-file-base64';

function Page(props) {

  const { columns, paginationOptions, movies, show, setShow, form, setForm, handledAdd, handledUpdate, errors, genders, file, handledUpdateCover } = props,
    [filterText, setFilterText] = useState(''),
    filteredItems = (movies.list ? movies.list : []).filter(item => {
      return (
        (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.director && item.director.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.release_date && item.release_date.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.created && item.created.includes(filterText))) ? true : false;
    });

  return (
    <>
      <Header></Header>
      <div className="container-fluid">
        <div className="row">
          <Sidebar></Sidebar>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 main-panel">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Películas</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2"></div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-sm-4 col-md-4 mb-3 mb-sm-0">
                    <div className="search-card input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="fas fa-search"></i>
                        </div>
                      </div>
                      <input type="search" className="form-control datatableSearch" placeholder="Buscar..." aria-label="Search orders" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-sm-8 col-md-8 mb-9 mb-sm-0" style={{ textAlign: "right" }}>
                    <Button variant="primary" onClick={() => setShow({ ...show, add: true })}><i className="fas fa-plus"></i>&nbsp;&nbsp;Crea Película</Button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  noDataComponent={
                    <p style={{ color: "rgb(200, 200, 200)", fontStyle: "italic", paddingTop: 40, paddingBottom: 60 }}>
                      <img className="avatar-img" src={"/assets/no-data.png"} alt="" />
                      <span className="no-data">No hay registros para mostrar</span>
                    </p>
                  }
                  paginationComponentOptions={paginationOptions}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer></Footer>

      {/* Add new movie */}
      <Modal show={show.add} onHide={() => setShow({ ...show, add: false })} className="modal-panel">
        <Modal.Header closeButton>
          <Modal.Title><i className="fas fa-plus"></i>&nbsp;&nbsp;Crea Película</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" style={{ borderColor: (errors.name) ? "rgb(200, 55, 55)" : "#e7eaf3" }} placeholder="Escriba el nombre..."
                    value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Estreno</Form.Label>
                  <DatePicker selected={form.release_date}
                    className="form-control" value={form.release_date} onChange={(date) => setForm({ ...form, release_date: date })} />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Trailer <i><small>(https://www.youtube.com/watch?v=<strong>LDCwYx3kfRc</strong>)</small></i> </Form.Label>
              <Form.Control type="text" placeholder="Escriba id del video (LDCwYx3kfRc)..." value={form.trailer} onChange={(e) => setForm({ ...form, trailer: e.target.value })} />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Director</Form.Label>
                  <Form.Control type="text" style={{ borderColor: (errors.director) ? "rgb(200, 55, 55)" : "#e7eaf3" }} placeholder="Escriba el nombre..."
                    value={form.director} onChange={(e) => setForm({ ...form, director: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Productor</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." value={form.producer} onChange={(e) => setForm({ ...form, producer: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Guión</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." value={form.script} onChange={(e) => setForm({ ...form, script: e.target.value })} />
                </Form.Group>
              </Col>
              <Col></Col>
            </Row>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Género</Form.Label>
              <Select options={genders} value={form.gender} isMulti={true} placeholder="Seleciona el género..." onChange={(e) => setForm({ ...form, gender: e })} />
              <label style={{ display: (errors.gender) ? "block" : "none", color: "rgb(200, 55, 55)", fontSize: 13 }}>* El género es requerida</label>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Cast</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Escriba el nombre de los actores..." value={form.cast} onChange={(e) => setForm({ ...form, cast: e.target.value })} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow({ ...show, add: false })}><i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar</Button>
          <Button variant="success" onClick={handledAdd}><i className="fas fa-save"></i>&nbsp;&nbsp;Guardar</Button>
        </Modal.Footer>
      </Modal>

      {/* Update movie */}
      <Modal show={show.edit} onHide={() => setShow({ ...show, edit: false })} className="modal-panel">
        <Modal.Header closeButton>
          <Modal.Title><i className="fas fa-edit"></i>&nbsp;&nbsp;Editar Película</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" style={{ borderColor: (errors.name) ? "rgb(200, 55, 55)" : "#e7eaf3" }} placeholder="Escriba el nombre..."
                    value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Estreno</Form.Label>
                  <DatePicker selected={form.release_date} placeholder="Escriba el nombre..."
                    className="form-control" value={form.release_date} onChange={(date) => setForm({ ...form, release_date: date })} />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Trailer</Form.Label>
              <Form.Control type="text" placeholder="Escriba el nombre..." value={form.trailer} onChange={(e) => setForm({ ...form, trailer: e.target.value })} />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Director</Form.Label>
                  <Form.Control type="text" style={{ borderColor: (errors.director) ? "rgb(200, 55, 55)" : "#e7eaf3" }} placeholder="Escriba el nombre..."
                    value={form.director} onChange={(e) => setForm({ ...form, director: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Productor</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." value={form.producer} onChange={(e) => setForm({ ...form, producer: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Guión</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." value={form.script} onChange={(e) => setForm({ ...form, script: e.target.value })} />
                </Form.Group>
              </Col>
              <Col></Col>
            </Row>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Género</Form.Label>
              <Select options={genders} value={form.gender} isMulti={true} placeholder="Seleciona el género..." onChange={(e) => setForm({ ...form, gender: e })} />
              <label style={{ display: (errors.gender) ? "block" : "none", color: "rgb(200, 55, 55)", fontSize: 13 }}>* El género es requerida</label>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Cast</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Escriba el nombre de los actores..." value={form.cast} onChange={(e) => setForm({ ...form, cast: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Activo</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control type="text" readOnly value={(form.active) ? "Activo" : "Desactivo"} />
                <Button variant={(!form.active) ? "success" : "danger"} onClick={() => setForm({ ...form, active: !form.active })}>
                  <i className={(form.active) ? "fas fa-toggle-on" : "fas fa-toggle-off"}></i>&nbsp;&nbsp;{(!form.active) ? "Activo" : "Desactivar"}
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow({ ...show, edit: false })}><i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar</Button>
          <Button variant="success" onClick={handledUpdate}><i className="fas fa-save"></i>&nbsp;&nbsp;Guardar</Button>
        </Modal.Footer>
      </Modal>

      {/* Change Cover */}
      <Modal show={show.cover} onHide={() => setShow({ ...show, cover: false })} className="modal-panel">
        <Modal.Header closeButton>
          <Modal.Title><i className="fas fa-edit"></i>&nbsp;&nbsp;Cover Película</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <span className="btn btn-primary btn-file">
          <i className="far fa-image pointer"></i>&nbsp;&nbsp;Cambiar cover...   <FileBase64 className="btn btn-primary"
              multiple={false}
              onDone={(file) => handledUpdateCover(file)} />
          </span>
          <Image src={(file.base64) ? file.base64 : "../../assets/cover.webp"} style={{ maxWidth: 400, marginTop: 20 }} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Page;