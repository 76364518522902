import { movies as moviesTypes } from '../constants/ActionTypes';
import { general as generalTypes } from '../constants/ActionTypes';
import axios from 'axios';

/**
 * Get list of movies
 * @returns 
 */
const getMovies = () => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    getListMovies(dispatch)
};

/**
 * Add new movie
 * @param {*} form 
 * @returns 
 */
const setMovie = (form) => async (dispatch) => {
    await axios.post(process.env.REACT_APP_URL + 'movie', form).then(resp => {
        getListMovies(dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Get movie by mid
 * @param {*} mid 
 */
const getMovie = (mid) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'movie/' + mid).then(resp => {
        dispatch({ type: moviesTypes.GET_MOVIE, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

/**
 * Update movie by mid
 * @param {*} form 
 * @returns 
 */
const setUpdate = (mid, form) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'movie/' + mid, { ...form, active: (form.active) ? 1 : 0 }).then(resp => {
        getListMovies(dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Update cover by mid
 * @param {*} mid 
 * @param {*} cover 
 * @returns
 */
const setUpdateCover = (mid, cover) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'movie-cover/' + mid, { cover: cover }).then(resp => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Remove movie by mid
 * @param {*} mid 
 * @param {*} onClose 
 * @returns 
 */
const setRemove = (mid, onClose) => async (dispatch) => {
    await axios.delete(process.env.REACT_APP_URL + 'movie/' + mid).then(resp => {
        getListMovies(dispatch, () => onClose())
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Export const
 */
const exportConst = {
    getMovies,
    getMovie,
    setMovie,
    setRemove,
    setUpdate,
    setUpdateCover
};

/**
 * Get list of movies
 * @param {*} dispatch 
 * @param {*} callBack 
 */
async function getListMovies(dispatch, callBack = function () { }) {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'movies').then(resp => {
        dispatch({ type: moviesTypes.GET_MOVIES, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
        callBack()
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

export default exportConst;