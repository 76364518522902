import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import GeneralActions from '../../../redux/actions/General';
import UserActions from '../../../redux/actions/Users';
import { withRouter } from 'react-router-dom';
import Page from './page';
import "./style.css"

const Header = ({ general, users, setSignOff, history }) => {

    useEffect(() => {
        if(!users.authUser) {
            history.push("/")
        }
    }, [users])

    const handledSignOff = () => {
        setSignOff(history)
    }

    return (
        <Page general={general} handledSignOff={handledSignOff} />
    );
}

const mapStateToProps = ({ general, users }) => ({
    general, users
});

const mapDispatchToProps = () => ({
    ...UserActions,
    ...GeneralActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Header));