import React, { useMemo, useState } from 'react';
import Page from './page';
import moment from 'moment';
import { connect } from 'react-redux';
import GeneralActions from '../../redux/actions/General';
import { withRouter } from 'react-router-dom';

const Petitions = ({ general, getPetitions }) => {

    useMemo(() => {
        getPetitions()
    }, []);

    const

        headers = [
            { label: "Nombre Película", key: "name" },
            { label: "Género", key: "gender" },
            { label: "Productor(es)", key: "producer" },
            { label: "Director/a", key: "director" },
            { label: "Guión", key: "script" },
            { label: "Dirección cinematográfica", key: "cinematographic_direction" },
            { label: "Edición", key: "edition" },
            { label: "Dirección de arte", key: "art_direction" },
            { label: "Diseño de producción", key: "production_design" },
            { label: "Diseñador de sonido", key: "sound_design" },
            { label: "Musicalización", key: "musicalization" },
            { label: "Canción (Tema Principal)", key: "song" },
            { label: "Efectos especiales", key: "special_effects" },
            { label: "Efectos visuales", key: "visual_effects" },
            { label: "Maquillaje", key: "make_up" },
            { label: "Vestuario", key: "slocker_roomong" },
            { label: "Actriz principal", key: "principal_actress" },
            { label: "Actriz secundaria", key: "supporting_actress" },
            { label: "Actor Principal", key: "main_actor" },
            { label: "Actor secundario", key: "secondary_actor" },
            { label: "Nombre completo", key: "full_name" },
            { label: "Cédula de identidad ", key: "identity_card" },
            { label: "Teléfono de contacto", key: "telephone_contact" },
            { label: "Correo electrónico", key: "email" },
            { label: "Creado", key: "created" }
        ],

        columns = [
            {
                name: 'Nombre Película', selector: row => row.name, minWidth: '18%', maxWidth: '18%', sortable: true, cell: row => (
                    <span>{row.name}</span>
                )
            }, {
                name: 'Género', selector: row => row.gender, minWidth: '15%', maxWidth: '15%', sortable: true, cell: row => (
                    <span>{row.gender}</span>
                )
            }, {
                name: 'Productor', selector: row => row.producer, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                    <span>{row.producer}</span>
                )
            }, {
                name: 'Director', selector: row => row.director, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                    <span>{row.director}</span>
                )
            }, {
                name: 'Guión', selector: row => row.script, minWidth: '15%', maxWidth: '15%', sortable: true, cell: row => (
                    <span>{row.script}</span>
                )
            }, {
                name: 'Creado', selector: row => row.created, minWidth: '12%', maxWidth: '12%', sortable: true, cell: row => (
                    <span>{moment(row.created).format("DD/MM/YYYY hh:mm A")}</span>
                )
            }
        ], paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    return (
        <Page columns={columns} paginationOptions={paginationOptions} general={general} headers={headers} />
    );
}

const mapStateToProps = ({ general }) => ({
    general
});

const mapDispatchToProps = () => ({
    ...GeneralActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Petitions));