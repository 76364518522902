import { general as generalTypes } from '../constants/ActionTypes';
import axios from 'axios';

/**
 * Loading modal
 * @param {*} action 
 * @returns 
 */
const getLoading = (action) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: action });
};

/**
 * Get configuration structure
 * @returns 
 */
const getConfig = () => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'config').then(resp => {
        dispatch({ type: generalTypes.CONFIG, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
};

/**
 * Add vote by user id
 * @returns 
 */
const setVote = (from) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.post(process.env.REACT_APP_URL + 'vote', from).then(resp => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
};

/**
 * Update config
 * @returns 
 */
 const setUpdateconfig = (from) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'config', from).then(resp => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
};

/**
 * Get vote by user and step
 * @returns 
 */
const getVoteByUser = (uid, step) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'vote-by-user/' + uid + "/" + step).then(resp => {
        let votes = [];
        resp.data.map(row => {
            votes.push(row.nominated_id)
        })
        dispatch({ type: generalTypes.VOTES, payload: votes });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
};

/**
 * Get list of votes
 * @returns 
 */
const getVotesResults = () => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'votes').then(resp => {
        dispatch({ type: generalTypes.GET_VOTES, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
};


/**
 * Add petition
 * @returns
 */
 const setPetition = (from) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.post(process.env.REACT_APP_URL + 'petition', from).then(resp => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
};

/**
 * Get Petitions
 * @returns 
 */
 const getPetitions = () => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'petitions').then(resp => {
        dispatch({ type: generalTypes.PETITIONS, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
};

/**
 * Export const
 */
const exportConst = {
    setVote,
    getConfig,
    getLoading,
    setPetition,
    getPetitions,
    getVoteByUser,
    setUpdateconfig,
    getVotesResults
};

export default exportConst;