import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";

function Page(props) {

    const { state } = props;

    return (
        <section id="h-contact">
            <Container>
                <ul className="nav-bar" style={{ display: (state.nav) ? "block" : "none" }}>
                    <li><Link to="/">Home</Link> <span>&gt;</span> <Link to="#">Contacto</Link></li>
                </ul>
                <h2 className="title-page">Contacto</h2>
                <Row>
                    <Col>
                        <p>Calle Salvador Espinal Miranda no. 28, Renacimiento</p>
                        <p>Tel: 809-328-3669</p>
                        <p>info@adocine.org</p>
                        <ul className="social-media">
                            <li><i className="fab fa-facebook-f"></i></li>
                            <li><i className="fab fa-instagram"></i></li>
                            <li><i className="fab fa-twitter"></i></li>
                        </ul>
                    </Col>
                    <Col>
                        <div class="container-map">
                            <iframe title="Map" class="responsive-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4500.18954563671!2d-69.88525260181764!3d18.47490336233395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf883dcf7aecc3%3A0xbb1cd7d8462c3b74!2sC.%20Luper%C3%B3n%2C%20Santo%20Domingo%2010210!5e0!3m2!1ses-419!2sdo!4v1652757324224!5m2!1ses-419!2sdo"
                            width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Page;