import React from "react";
import Footer from "../../modules/page/footer";
import Header from "../../modules/page/header";
import Contact from "../../modules/page/Contact";
import "./style.css";

function Page(props) {

  return (
    <>
      <Header></Header>
      <Contact nav={true}></Contact>
      <Footer></Footer>
    </>
  );
}

export default Page;
