import React, { useMemo, useState } from 'react';
import Page from './page';
import moment from 'moment';
import { connect } from 'react-redux';
import NomineeRowActions from '../../redux/actions/NomineeRow';
import PositionsActions from '../../redux/actions/Positions';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const NomineeRow = ({ nomineeRow, positions, getNomineeRows, getNomineeRow, setNomineeRow, setUpdate, setRemove, getPositions }) => {

    const [show, setShow] = useState({ add: false, edit: false }),
        [nid, setNid] = useState(null),
        [form, setForm] = useState({ active: 1 }),
        [errors, setErrors] = useState({}),
        [options, setOptions] = useState([])


    useMemo(() => {
        getNomineeRows()
        getPositions()
    }, []);

    useMemo(() => {
        if (positions.list) {
            let list = []
            positions.list.map(row => {
                list.push({ value: row.position_id, label: row.name })
            })
            setOptions(list)
        }
    }, [positions.list]);

    useMemo(() => {
        if (nomineeRow.selected) {
            let positions = (nomineeRow.selected.positions) ? nomineeRow.selected.positions.split(",") : [], list = [];
            options.map(row => {
                if (positions.includes(String(row.value))) {
                    list.push(row)
                }
            })
            setForm({
                name: nomineeRow.selected.name,
                positions: list,
                active: parseInt(nomineeRow.selected.active)
            })
            setShow({ ...show, edit: true })
        }
    }, [nomineeRow.selected]);

    const

        /**
         * Handled add new position
         */
        handledAdd = () => {
            let positions = []
            if (handleValidate()) {
                form.positions.map(row => {
                    positions.push(row.value)
                })
                setNomineeRow({ ...form, positions: positions })
                setShow({ ...show, add: false })
                setForm({ name: "", active: 1 })
            }
        },

        /**
         * Handled update nominee row by nid
         */
        handledUpdate = () => {
            let positions = []
            if (handleValidate()) {
                form.positions.map(row => {
                    positions.push(row.value)
                })
                setUpdate(nid, { ...form, positions: positions })
                setShow({ ...show, edit: false })
                setForm({ name: "", active: 1 })
            }
        },

        /**
         * Handled show edit
         */
        handledShowEdit = (nid) => {
            setNid(nid)
            getNomineeRow(nid)
        },

        /**
         * Handled remove nominee row
         */
        handledRemove = (nid) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Eliminar Renglón</h3>
                            <p>¿Realmente deseas eliminar este renglón?</p>
                            <div className="text-center">
                                <button className="btn btn-secondary" onClick={onClose} style={{ float: "left" }}><i className="fas fa-times"></i>&nbsp;Cancel</button>
                                <button className="btn btn-success" onClick={() => setRemove(nid, onClose)} style={{ float: "right" }}><i className="fas fa-check"></i>&nbsp;Aceptar</button>
                            </div>
                        </div>
                    )
                }
            });
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = () => {
            setErrors({})
            if (!form.name) {
                setErrors({ name: true });
                return false;
            }
            return true;
        };

    const columns = [
        {
            name: 'ID', selector: row => row.nominee_row_id, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span>{row.nominee_row_id}</span>
            )
        }, {
            name: 'Nombre', selector: row => row.name, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{row.name}</span>
            )
        }, {
            name: 'Posiciones', selector: row => row.name, minWidth: '40%', maxWidth: '40%', sortable: true, cell: row => (
                <span>{row.positions}</span>
            )
        }, {
            name: 'Estado', selector: row => row.active, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span style={{ color: ((parseInt(row.active)) ? "#31821b" : "rgb(200, 55, 55)") }}>{(parseInt(row.active)) ? "Activo" : "Desactivo"}</span>
            )
        }, {
            name: 'Creado', selector: row => row.created, minWidth: '12%', maxWidth: '12%', sortable: true, cell: row => (
                <span>{moment(row.created).format("DD/MM/YYYY hh:mm A")}</span>
            )
        }, {
            name: '', minWidth: '8%', maxWidth: '8%', cell: row => (
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "space-between" }}>

                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Editar Usuario"
                        style={{ margin: "0 auto" }} onClick={(uid) => handledShowEdit(row.nominee_row_id)}><i className="far fa-edit pointer"></i></button>

                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Eliminar Usuario"
                        style={{ margin: "0 auto" }} onClick={() => handledRemove(row.nominee_row_id)}><i className="far fa-trash-alt pointer"></i></button>
                </div>
            )
        }
    ], paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    return (
        <Page columns={columns} paginationOptions={paginationOptions} nomineeRow={nomineeRow} show={show} setShow={setShow} form={form} errors={errors}
            setForm={setForm} handledAdd={handledAdd} handledRemove={handledRemove} handledUpdate={handledUpdate} options={options} />
    );
}

const mapStateToProps = ({ nomineeRow, positions }) => ({
    positions,
    nomineeRow
});

const mapDispatchToProps = () => ({
    ...PositionsActions,
    ...NomineeRowActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(NomineeRow));