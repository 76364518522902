import React, { useMemo, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import MoviesActions from '../../redux/actions/Movies';
import GeneralActions from '../../redux/actions/General';
import { withRouter } from 'react-router-dom';

const Home = ({ movies, getMovies, setPetition, users }) => {

    const [show, setShow] = useState(false),
        [errors, setErrors] = useState({}),
        [done, setDone] = useState(false),
        [form, setForm] = useState({})

    useMemo(() => {
        getMovies()
    }, [])

    const handledAdd = () => {
        setPetition(form)
        setDone(true)
    }

    return (
        <Page movies={movies} show={show} setShow={setShow} form={form} done={done}
            setForm={setForm} errors={errors} handledAdd={handledAdd} users={users} />
    );
}

const mapStateToProps = ({ movies, users }) => ({
    users,
    movies
});

const mapDispatchToProps = () => ({
    ...MoviesActions,
    ...GeneralActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Home));