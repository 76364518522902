import React from 'react';
import { Col, Container, Row, Image } from "react-bootstrap";
import "./style.css";

function Page(props) {

    return (
        <section id="h-board-directors">
            <Container>
                <h2 className="title-page">Junta Directiva</h2>
                <Row>
                    <Col style={{ textAlign: "center" }}>
                        <Image src={"../assets/board_directors/hans_garcias.png"} />
                        <h3>Hans García</h3>
                        <label>Presidente</label>
                    </Col>
                    <Col style={{ textAlign: "center" }}>
                        <Image src={"../assets/board_directors/miguel_alcantara.png"} />
                        <h3>Alan Nadal Piantini</h3>
                        <label>Vice Presidente</label>
                    </Col>
                    <Col style={{ textAlign: "center" }}>
                        <Image src={"../assets/board_directors/alan_nadal_piantini.png"} />
                        <h3>Miguel Alcántara</h3>
                        <label>Vocal</label>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Page;